

import { axiosInstance } from '../../utilities/http'

import { loadStripe } from "@stripe/stripe-js"

import { createStandaloneToast } from "@chakra-ui/react"


const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY)

const toast = createStandaloneToast()


const loginUser = (email, password, router) => {
  return async (dispatch) => {
    try {
      const { data: { token }} = await axiosInstance.post('/api/auth/login', { email, password }, { headers: { 'Content-Type': 'application/json' } })
  
      dispatch({type: 'SET_USER_TOKEN', token})
  
      localStorage.setItem('token', token)
  
      router.push('/app/models')
    } catch (error) {      
      if (error.response && error.response.data.message) {
        const { data: { message } } = error.response
        toast({
          title: message,
          status: "info",
          duration: 2000,
          isClosable: true,
          position:'top',
          variant: "top-accent"
        })
      } else {
        toast({
          title: 'Something went wrong',
          status: "error",
          duration: 2000,
          isClosable: true,
          variant: "top-accent"
        })
      }
    }
  }
}
export { loginUser }

const loginUserFacebook = (router) => {
  return async (dispatch) => {
    window.open('/api/auth/facebook', 'Login','height=600,width=400')
    window.loginCallBack = (token) => {
      dispatch(loginUserToken(token, router))
    }
  }
}
export { loginUserFacebook }

const loginUserGoogle = (router) => {
  return async (dispatch) => {
    window.open('/api/auth/google', 'Login','height=600,width=400')
    window.loginCallBack = (token) => {
      dispatch(loginUserToken(token, router))
    }
  }
}
export { loginUserGoogle }

const loginUserToken = (token, router) => {
  return async (dispatch) => {
    try {  
      dispatch({type: 'SET_USER_TOKEN', token})
  
      localStorage.setItem('token', token)
  
      router.push('/app/models')
    } catch (error) {      
      if (error.response && error.response.data.message) {
        const { data: { message } } = error.response
        toast({
          title: message,
          status: "info",
          duration: 2000,
          isClosable: true,
          position:'top',
          variant: "top-accent"
        })
      } else {
        toast({
          title: 'Something went wrong',
          status: "error",
          duration: 2000,
          isClosable: true,
          variant: "top-accent"
        })
      }
    }
  }
}
export { loginUserToken }

const signupUser = (name, surname, email, password, router) => {
  return async (dispatch) => {
    try {
      const { data: { token }} = await axiosInstance.post('/api/auth/signup', { name, surname, email, password }, { headers: { 'Content-Type': 'application/json' } })
  
      dispatch({type: 'SET_USER_TOKEN', token})
  
      localStorage.setItem('token', token)
  
      router.push('/app/models')
    } catch (error) {
      if (error.response && error.response.data.message) {
        const { data: { message } } = error.response
        toast({
          title: message,
          status: "info",
          duration: 2000,
          isClosable: true,
          position:'top',
          variant: "top-accent"
        })
      } else {
        toast({
          title: 'Something went wrong',
          status: "error",
          duration: 2000,
          isClosable: true,
          variant: "top-accent"
        })
      }
    }
  }
}
export { signupUser }

const signoutUser = (router) => {
  return async (dispatch) => {
    dispatch({type: 'SET_USER_TOKEN', token: null})

    dispatch({type: 'RESET_DIALOGS'})
    dispatch({type: 'RESET_MODEL'})
    dispatch({type: 'RESET_PIALOGS'})
    dispatch({type: 'RESET_PODEL'})
    dispatch({type: 'RESET_USER'})

    localStorage.removeItem('token')

    router.push('/app/login')
  }
}
export { signoutUser }

const showPortal = (priceId) => {
  return async (_, getState) => {
    const { user } = getState().userReducer
    if (user?.subscriptionStatus === 'active') {
			const { data } = await axiosInstance.get(`/api/billing/portal`)
			window.location.href = data.url
		} else {
			const stripe = await stripePromise
			const { data } = await axiosInstance.get(`/api/billing/checkout?priceId=${priceId}`)
			await stripe.redirectToCheckout({ sessionId: data.sessionId })
		}
  }
}
export { showPortal }

const getSession = (sessionId, router) => {
  return async (dispatch, getState) => {
    const { data } = await axiosInstance.get(`/api/billing/session?session_id=${sessionId}`)

    const { user } = getState().userReducer
    dispatch({type: 'SET_USER', user: {...user, ...data}})

    router.push('/app/models', null, { shallow: true })
  }
}
export { getSession }

const getUser = () => {
  return async (dispatch, getState) => {
    const { data } = await axiosInstance.get(`/api/auth/get`)

    const { user } = getState().userReducer
    dispatch({type: 'SET_USER', user: {...user, ...data}})
  }
}
export { getUser }

import React, { useEffect} from 'react'

import { useRouter } from 'next/router'

import { useDispatch, useSelector } from 'react-redux'

import * as AuthActions from '../../store/auth'

import { ChangePlanDialog } from '../../components/Pialogs/ChangePlanDialog'

import { Flex, Button, List , ListItem, Icon, useDisclosure } from '@chakra-ui/react'

import { FaUserCircle } from 'react-icons/fa'

import { BiWindow } from 'react-icons/bi'

import { HiLogout } from 'react-icons/hi'
 
const NavBarRight = () => {

  const router = useRouter()

  const token = useSelector(state => state.userReducer.token)

  const user = useSelector(state => state.userReducer.user)

  const status = user?.subscriptionStatus

  const dispatch = useDispatch()

  const signoutUser = () => dispatch(AuthActions.signoutUser(router))

  const showPortal = () => dispatch(AuthActions.showPortal())

  const getSession = (sessionId) => dispatch(AuthActions.getSession(sessionId, router))

  const getUser = () => dispatch(AuthActions.getUser())

  const { isOpen, onOpen, onClose } = useDisclosure()


  useEffect(() => {
    if (token)
      getUser()
	}, [])

  useEffect(() => {
		const sessionId = router.query.session_id
		if (sessionId && sessionId !== 'null') {
			getSession(sessionId)
		} else if (sessionId === 'null') {
      router.push('/app/models', null, { shallow: true })
    }
	}, [router.query.session_id])

  // const goToAccount = () => {
  //   router.push('/app/account')
  //   gtag('event', 'go_to_account', {})
  // }

  const goToEndpoints = () => {
    router.push('/app/models')
  }

  const goToPortal = () => {
    if (status === 'active') {
      showPortal()
    } else {
      onOpen()
    }
  }

  // note: hacky way to match client and server responses
  if (token) {
    return (
      <List marginLeft="auto">
        <ListItem display='inline'>
          <Button  variant="ghost" leftIcon={<Icon as={FaUserCircle}/>} onClick={() => goToPortal()}>{status !== 'active' ?  'Upgrade' : 'Billing'}</Button>
        </ListItem>
        {!router.pathname.startsWith('/app/models') && <ListItem display='inline'>
          <Button  variant="ghost" leftIcon={<Icon as={BiWindow}/>} onClick={() => goToEndpoints()}>Endpoints</Button>
        </ListItem>}
        <ListItem display="inline" marginLeft="20px" marginRight="20px">
          <Button variant="ghost" leftIcon={<Icon as={HiLogout}/>} onClick={signoutUser}>Signout</Button>
        </ListItem>
        <ChangePlanDialog isOpen={isOpen} onClose={onClose}/>
      </List>
    )
  } else {
    return (
      <List marginLeft='auto'>
        {!router.pathname.startsWith('/app/login') && <ListItem display="inline" margin="20px" marginLeft="auto">
          <Button  variant="ghost" iconSpacing="4" leftIcon={<Icon as={FaUserCircle}/>} onClick={() => router.push('/app/login')}>Login</Button>
        </ListItem>}
        {!router.pathname.startsWith('/app/register') && <ListItem display="inline" margin="20px">
          <Button  variant="ghost" iconSpacing="4" leftIcon={<Icon as={FaUserCircle}/>} onClick={() => router.push('/app/register')}>Register</Button>
        </ListItem>}
      </List>
    )
  }
}

const NavBar = (props) => {
  return (
    <nav>
      <Flex paddingTop="5" paddingBottom="5" alignItems="center" paddingLeft="40px" borderBottom="1px solid #eaeaea">
        {props.children}
        <NavBarRight/>
      </Flex>
    </nav>
  )
}

export { NavBar }

import React from 'react'

import data from "../../assets/pricing"

import PricingSectionWrapper from "../Landing/Pricing/pricingSection.style"

import { IoMdCheckmark } from "react-icons/io"

import { useDispatch } from 'react-redux'

import * as AuthActions from '../../store/auth'

import { Modal, ModalOverlay, Icon, Image, ModalContent,  ModalBody, ModalHeader, Flex, Box, Text, Button, Heading } from "@chakra-ui/react"

const PriceBlock = ({pricing, index}) => {

  const dispatch = useDispatch()

  const showPortal = (priceId) => dispatch(AuthActions.showPortal(priceId))
  return (
    <Box margin='20px' minWidth="200px">
      <Box className="pricing-block" boxShadow='0 0 8px #48484852 !important'>
        <Flex className="thumb-block" height='120px !important' justifyContent="center">
          <Image objectFit='contain' height='100%' src={pricing.thumb} />
        </Flex>
        <Box className="price-block">
          <Heading as="h3">{pricing.price} <Text display="inline" fontSize='16px'>/ month</Text></Heading>
          <Text>{pricing.name}</Text>
        </Box>
        <Box className="details-block" marginBottom='32px' marginLeft="20px">
          {pricing.services.map((service, index) => (
            <Text marginBottom='12px' key={index}>
              <Icon as={IoMdCheckmark} marginRight="12px"/>
              {service.content}
            </Text>
          ))}
        </Box>
        <Button className={index === 0 ? "non-hoverable" : "non-hoverable-active"} onClick={() => showPortal(pricing.priceId)} disabled={index === 0}>{index === 0 ? 'Selected' : 'Upgrade'}</Button>
      </Box>
    </Box>
  )
}

const ChangePlanDialog = ({isOpen, onClose}) => {
  return (
  <Modal size="4xl" isOpen={isOpen} onClose={onClose} isCentered>
    <ModalOverlay />
    <ModalContent >
      <ModalHeader backgroundColor="rgb(51, 51, 51)" color="white" textAlign="center">
        Upgrade your plan
      </ModalHeader>
      <ModalBody>
        <PricingSectionWrapper style={{justifyContent: 'center', padding: 0}}>
          <Flex justifyContent='center' padding="0">
            {data.pricings.map((pricing, index) => <PriceBlock pricing={pricing} key={index} index={index}/>)}
          </Flex>
        </PricingSectionWrapper>
      </ModalBody>
    </ModalContent>
  </Modal>
  )
}

export { ChangePlanDialog }

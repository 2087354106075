const billingProducts = process.env.NEXT_PUBLIC_STRIPE_BILLING_PRODUCTS.split(',')

const products = [
    {price: billingProducts[0], interval: 'month', type: 'bootstrap'},
    {price: billingProducts[1], interval: 'year', type: 'bootstrap'},
    {price: billingProducts[2], interval: 'month', type: 'growth'},
    {price: billingProducts[3], interval: 'year', type: 'growth'}
]

const getPriceId = (type, interval) => {
    const priceObj = products.find(p => p.type === type && p.interval === interval)
    return priceObj.price
}

const data = {
    pricings: [
      {
        thumb: require('./plant1.png'),
        name: 'Free',
        price: '$0',
        description: 'Experimentation',
        btnText: 'Get Started',
        priceId: null,
        services:[
            {
                content: 'Unlimited APIs',
                className: 'on'
            },
            {
                content: 'Auth, Payments, Databases',
                className: 'on'
            },
            {
                content: '1k API Requests',
                className: 'on'
            }
        ]
      },{
        thumb: require('./plant2.png'),
        name: 'Bootstrap',
        price: '$20',
        description: 'Product-Market Fit',
        btnText: 'Join Now',
        priceId: getPriceId('bootstrap', 'month'),
        services:[
            {
                content: 'Unlimited APIs',
                className: 'on'
            },
            {
                content: 'Auth, Payments, Databases',
                className: 'on'
            },
            {
                content: '20k API Requests',
                className: 'on'
            }
        ]
      },
      {
        thumb: require('./plant3.png'),
        name: 'Startup',
        price: '$100',
        description: 'Growth Phase',
        btnText: 'Join Now',
        priceId: getPriceId('growth', 'month'),
        services:[
            {
                content: 'Unlimited APIs',
                className: 'on'
            },
            {
                content: 'Auth, Payments, Databases',
                className: 'on'
            },
            {
                content: '200k API Requests',
                className: 'on'
            }
        ]
      }
      
    ]
    
  };
  export default data;
  